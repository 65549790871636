import React from 'react';
import Events from '../Components/Events';
import BrowserIntro from '../Components/BrowserIntro';
import MobileIntro from '../Components/MobileIntro';
import MobileEvents from '../Components/MobileEvents';
import MobileWatch from "../Media/Where To Watch Mobile.png";
import watch from "../Media/Where To Watch.png";
import Meet from "../Media/Meet Our Pastor.png";
import MeetMobile from "../Media/Meet Our Pastor Mobile.png";
import Magazine from "../Media/Download Our Magazine.png";
import MagazineMobile from "../Media/Download Our Magazine Mobile.png";
import NewMembersClass from '../Media/nextstepsclass.png';
import NewMembersClassMob from '../Components/NewMembersClassMob';
import IHaveDecided from '../Components/IHaveDecided';
import DecidedMobile from '../Components/DecidedMobile';


function Main() {
    // Get the current width of the viewport
    const screenWidth = window.innerWidth;

    // Define a threshold width (640 in this case)
    const thresholdWidth = 640;

    // Determine whether to show the intro or slideshow based on screen width
    const shouldShowIntro = screenWidth < thresholdWidth;

    return (
        <div>
            {shouldShowIntro ? (
                <div className="container">
                    <MobileIntro />
                </div>
            ) : (
                <BrowserIntro />
            )}

            {shouldShowIntro ? (
                <div className="container">
                    <a href="/about/Meet-Our-Pastor">
                        <img src={MeetMobile} className="card-img-top" alt="..." />
                    </a>
                </div>
            ) : (
                <a href="/about/Meet-Our-Pastor">
                    <img src={Meet} className="card-img-top" alt="..." />
                </a>
            )}

            {shouldShowIntro ? (
                <div className="container">
                    <a href="https://heyzine.com/flip-book/f5e1cc7dd2.html">
                        <img src={MagazineMobile} className="card-img-top" alt="..." />
                    </a>
                </div>
            ) : (
                <a href="https://heyzine.com/flip-book/f5e1cc7dd2.html">
                    <img src={Magazine} className="card-img-top" alt="..." />
                </a>
            )}

            {shouldShowIntro ? (
                <div className="container">
                    <a href="/live-stream">
                        <img src={MobileWatch} className="card-img-top" alt="..." />
                    </a>
                </div>
            ) : (
                <a href="https://www.youtube.com/watch?v=PidKrT2-KkI">
                    <img src={watch} className="card-img-top" alt="..." />
                </a>
            )}

            {/* <MajorEvent /> */}

            {shouldShowIntro ? (
                <div className="container">
                    <NewMembersClassMob />
                </div>
            ) : (
                <a href="/Events/NewMembersClass">
                    <img src={NewMembersClass} className="card-img-top" alt="..." />
                </a>
            )}

            {shouldShowIntro ? (
                <div className="container">
                    <DecidedMobile />
                </div>
            ) : (
                <IHaveDecided />
            )}

            {shouldShowIntro ? (
                <div className="container">
                    <MobileEvents />
                </div>
            ) : (
                <Events />
            )}
        </div>
    );
}

export default Main;
