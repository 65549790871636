import Empowerment from "../Media/Main Graphic - EMPOWERMENT SUNDAYS.jpg";
import September2Remember from "../Media/September2Remember.jpg";
import OctoberTakeover from "../Media/OctoberTakeoverTCVC.jpg";
import WorshipTeam from "../Media/Worship Team Auditions.jpg";
import HarvestFest from "../Media/Harvest Fest 2024 - Block Party.jpg";
import SilverSaintsSessions from "../Media/SilverSaintsSessions.png";
import CyberMemberWeekend from "../Media/CyberMember.jpg";
import ChosenKids from "../Media/Chosen Kids + Chosen Youth Sign Up EVENT.jpg";
import superSaturday from "../Media/Super Saturday Monthly.jpg";
import singles from "../Media/Singles Meet and Greet TCVC.jpg";


const eventCards = [
    
    {
        title: "September To Remember",
        text: "September 1 - 29",
        month: "September",
        day: "1-29",
        eventDescription: "Join us this September at The Chosen Vessel Church as we celebrate our 104th Church Anniversary with a month of powerful services, both in-person and online. Experience inspiring messages from anointed speakers including Dr. Valerie Moore, Dr. Wanda Frazier-Parker, Bishop Marc House, Bishop Darius Nixon, and Pastor Erick Bowens.",
        imgSrc: September2Remember,
    },
    {
        title: "Singles Meet and Greet",
        text: "September 29",
        month: "September",
        day: "29",
        eventDescription: "Join us for the Singles Meet & Greet on Sunday, September 29 following morning worship at Lady Ar's Cafe (in the Family Life Center) at The Chosen Vessel Church! This is a free event, but registration is required by Monday, September 23. Don’t miss this opportunity to connect with other singles in a relaxed and welcoming atmosphere.",
        imgSrc: singles,
        link: "https://thechosenvesselchurch.breezechms.com/form/2db313",
    },
    {
        title: "Worship Team Auditions",
        text: "October 5",
        month: "October",
        day: "5",
        eventDescription: "Join us on Saturday, October 5 at 12:00 PM for Worship Team Auditions at The Chosen Vessel Church! Don't miss your chance to share your gift.",
        imgSrc: WorshipTeam,
        link: "https://thechosenvesselchurch.breezechms.com/form/worshipauditions",
    },
    {
        title: "Chosen Kids & Chosen Youth Volunteer Sign-up",
        text: "October 5",
        month: "October",
        day: "5",
        eventDescription: "Mark your calendars for Saturday, October 5 at 12:00 PM! Come be a part of Chosen Kids & Chosen Youth by signing up to volunteer and attend the orientation. Your involvement makes a difference!",
        imgSrc: ChosenKids,
        link: "https://thechosenvesselchurch.breezechms.com/form/chosenkidsandyouth-volunteerevent",
    },
    {
        title: "Super Saturday at The Chosen Vessel Church",
        text: "October 5",
        month: "October",
        day: "5",
        eventDescription: "Get ready for Super Saturday at The Chosen Vessel Church on Saturday, October 5 at 11:00 AM! Join us for a day of learning and fellowship, starting with the Next Steps (New Members Class) at 10:00 AM, followed by a General Session for pastors, CMS, and ministry servants at 11:00 AM. Don't miss the Ministry Team Meetings and lunch at 12:00 PM. Register and be part of this powerful day!",
        imgSrc: superSaturday,
    },
    {
        title: "October Takeover",
        text: "October 6 - 27",
        month: "October",
        day: "6-27",
        eventDescription: "Join us this October for an impactful month of worship and inspiration at Chosen Vessel Church's October Takeover, featuring powerful messages from esteemed pastors and bishops. Experience the anointing in-person or online every Sunday at 11:00 AM CST from October 6th to October 27th",
        imgSrc: OctoberTakeover,
    },
    {
        title: "Silver Saints Sessions",
        text: "Starting September",
        month: "September",
        day: "10",
        eventDescription: `On Tuesday, September 10, 2024, at 1:00 pm, there will be a gathering at Lady Ar's Cafe, located in Chosen Vessel's Community Life & Learning Center. The discussion topic will be "Are You Prepared?" focusing on advance planning and final expenses. Join us for an important conversation.`,
        imgSrc: SilverSaintsSessions,
        link: "https://thechosenvesselchurch.breezechms.com/form/8cefc87291",
    },
    {
        title: "Cyber Member Weekend",
        text: "October 26-27",
        month: "October",
        day: "26th-27th",
        eventDescription: "The Chosen Vessel Everywhere Weekend, happening on October 26-27, invites all online members to join in person for a night of fellowship at Dave & Buster's, followed by worship and a family dinner. The event will culminate with a special Reunion Tour featuring Kirk Franklin, Yolanda Adams, Marvin Sapp, Clark Sisters, and Fred Hammond. CLICK FLYER FOR MORE INFORMATION",
        imgSrc: CyberMemberWeekend,
        link: "/ministries/Chosen-Vessel-Everywhere",
    },
    {
        title: "Harvest Fest",
        text: "October 31",
        month: "October",
        day: "31",
        eventDescription: `Join us on October 31st from 7 PM to 9 PM at The Chosen Vessel Church for our annual Harvest Fest! This family-friendly event is a celebration for the entire community, offering a safe and welcoming environment for all. Enjoy a variety of activities, including Trunk or Treat, delicious food, fun games, a game truck, lively music, and wonderful fellowship. Best of all, it’s completely free! Come dressed in your favorite costumes and make lasting memories with friends and family. We can’t wait to celebrate with you!`,
        imgSrc: HarvestFest,
    },
    {
        title: "Empowerment Sundays At the Vessel",
        text: "Starting February",
        month: "Feb",
        eventDescription: "Growth Connect Class for Ages 18-45 at our Main Campus in Classroom A-7: Attendees should use our front entrance and our Greeters & Membership Services will be available to direct you. Wisdom In The Word Class for Ages 46 and Up at our Main Campus in our Bishop R E Young Classroom: Attendees should use our front entrance and our Greeters & Membership Services will be available to direct you. Walking Through The Word for  Ages 18 and Up in our Virtual Classroom: Attendees should join us by clicking the flyer and being taken to the meeting.",
        imgSrc: Empowerment,
        link: "/ministries/SundaySchool",
    },
];

export default eventCards;
